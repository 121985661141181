import React, {useMemo} from "react";
import "./oauthLink.css";

const OAuthLink = (props) => {
    const {title, authUrl, scope, clientId, redirectUrl, state, pkce, codeChallenge} = props;

    const href = useMemo(() => {
        let ret = `${authUrl}?response_type=code&scope=${scope}&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${state}`;

        if (pkce) {
            ret += `&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        }

        return ret;
    }, [authUrl, scope, clientId, redirectUrl, state, pkce, codeChallenge]);

    return <div className="oauth-link">
        <a
            className="primary acquire"
            href={href}
            target="_self"
            rel="noopener noreferrer"
        >
            Login to {title}
        </a>
    </div>
}

export default OAuthLink;
