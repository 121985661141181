import React from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime, objects, sorting} from "@atttomyx/shared-utils";

const RobotCard = (props) => {
    const { appRenderer, robot, filters, userIdToLogin, children } = props;

    return <Card className="robot-card" raised={true}>
        <CardContent>
            <CardTitle>
                {robot.name}
            </CardTitle>
            <CardData
                filters={filters}
                data={robot}
                keyToMetadata={{
                    appIds: {
                        title: "Apps",
                        formatter: (data) => data.map(appRenderer.render).sort(sorting.alphabetize),
                    },
                    email: {
                        title: "Email",
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                    login: {
                        title: "Login",
                        formatter: datetime.getFullDateAndTime,
                        getter: (item) => {
                            const login = userIdToLogin[item.id] || {};

                            return login.created;
                        },
                        hidden: objects.nullOrUndefined(userIdToLogin[robot.id]),
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default RobotCard;
