import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {useFiltered} from "@atttomyx/shared-hooks";
import {IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon, People as UsersIcon,} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import AccountCard from "../../cards/accountCard/accountCard";
import AccountFilters from "../../filters/accountFilters/accountFilters";
import * as accountService from "../../services/internal/accounts";
import {sorting} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {PAGE_ACCOUNT, PAGE_USERS} from "../../constants";

const getSortByAppAndName = (appIdToTitle) => {
    const sortByApp = sorting.getSortByIndexByField(appIdToTitle, "appId");

    return sorting.getCompositeSorter([sortByApp, sorting.sortByName]);
};

const AccountsPage = (props) => {
    const { history, snackbar, dimensions, apps, appRenderer, billingRenderer, accounts, filters, onDelete } = props;
    const [ account, setAccount ] = useState(null);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(accounts, filters, getSortByAppAndName(appRenderer.idToRendered));

    useEffect(() => {
        if (!account) {
            setShowDelete(false);
        }
    }, [account]);

    return <div className="accounts-page">
        <Typography variant="h5" paragraph={true}>
            Accounts
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={AccountFilters}
                formProps={{
                    apps: apps,
                    appRenderer: appRenderer,
                    billingRenderer: billingRenderer,
                }}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(account) =>
                <AccountCard key={account.id} appRenderer={appRenderer} billingRenderer={billingRenderer} account={account} filters={filters}>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => {
                                    setAccount(account);
                                    setShowDelete(true);
                                }}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Users"
                                onClick={() => router.redirectTo(history, PAGE_USERS + "?accountId=" + account.id)}>
                        <UsersIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => router.redirectTo(history, PAGE_ACCOUNT + "/" + account.id)}>
                        <EditIcon/>
                    </IconButton>
                </AccountCard>}
        />
        <FloatingAddButton title="Add account" position="higher"
                           onClick={() => router.redirectTo(history, PAGE_ACCOUNT)}
        />
        {showDelete && account ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={account.name}
            onCancel={() => {
                setShowDelete(false);
                setAccount(null);
            }}
            onDelete={(accountId) => {
                setShowDelete(false);
                setAccount(null);
                onDelete(accountId);
            }}
            deleter={(success, failure) => accountService.deleteAccount(account.id, success, failure)}
        /> : null}
    </div>
}

export default withRouter(AccountsPage);
