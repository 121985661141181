import axios from "axios";

export const listIntegrations = (cursor, limit, success, failure) => {
    let url = "/api/v1/oauth/integration/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveTokens = (integrationId, code, success, failure) => {
    const url = `/api/v1/oauth/tokens`;

    axios.post(url, {
        integrationId: integrationId,
        code: code,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};
