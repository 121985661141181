import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import ChargeForm from "../../forms/chargeForm/chargeForm";
import * as chargeService from "../../services/internal/charges";
import {datetime, forms} from "@atttomyx/shared-utils";
import {CHARGE_IMMEDIATE, CHARGE_ONCE, CHARGE_RECURRING} from "../../constants";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "400px",
    },
}));

const toData = (charge) => {
    const today = datetime.today();
    const type = charge.recurring ? CHARGE_RECURRING : charge.immediate ? CHARGE_IMMEDIATE : CHARGE_ONCE;
    const money = charge.money || {
        dollars: 0,
        cents: 0,
    };

    money.valid = money.dollars >= 0 && money.cents >= 0;

    return {
        reason: charge.reason,
        money: money,
        type: charge.recurring ? CHARGE_RECURRING : charge.immediate ? CHARGE_IMMEDIATE : CHARGE_ONCE,
        nextBillDue: charge.nextBillDue,
        valid: charge.reason
            && (type === CHARGE_IMMEDIATE || (charge.nextBillDue && datetime.isAfter(charge.nextBillDue, today)))
            && money.valid,
    }
};

const ChargeDialog = (props) => {
    const { snackbar, billingId, charge, onCancel, onSave } = props;
    const [ data, setData ] = useState(toData(charge));
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved, payment) => {
            snackbar.setSuccess(payment && payment.success ? "Payment success" : "Charge saved");
            setSaving(false);
            onSave(saved, payment);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const modified = {
            billingId: billingId,
            reason: data.reason,
            money: data.money,
            immediate: data.type === CHARGE_IMMEDIATE,
            recurring: data.type === CHARGE_RECURRING,
            nextBillDue: data.type !== CHARGE_IMMEDIATE ? data.nextBillDue : null,
        };

        if (charge.id) {
            chargeService.saveCharge(charge.id, modified, success, failure);

        } else {
            chargeService.createCharge(modified, success, failure);
        }
    }

    return <Dialog
        open={true}
        aria-labelledby="charge-dialog"
        classes={useStyles()}
    >
        <ClosableDialogTitle onClose={onCancel}>
            {charge.id ? "Edit Charge" : "Add Charge"}
        </ClosableDialogTitle>
        <DialogContent className="charge-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <ChargeForm
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(charge), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default ChargeDialog;
