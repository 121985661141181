import axios from 'axios';
import * as profileService from "../profile";

export const listUsers = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/user/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createUser = (accountId, user, success, failure) => {
    const url = "/api/v1/internal/user/";

    axios.post(url, {
        accountId: accountId,
        imageUrl: user.imageUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        alias: user.alias,
        email: user.email,
        phone: user.phone,
        birthday: user.birthday,
        roles: user.roles,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveUser = (accountId, userId, user, success, failure) => {
    const url = `/api/v1/internal/user/${userId}/`;

    axios.put(url, {
        accountId: accountId,
        imageUrl: user.imageUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        alias: user.alias,
        email: user.email,
        phone: user.phone,
        birthday: user.birthday,
        roles: user.roles,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const addToAccount = (userId, accountId, success, failure) => {
    const url = `/api/v1/internal/user/${userId}/${accountId}/`;

    axios.put(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const removeFromAccount = (userId, accountId, success, failure) => {
    const url = `/api/v1/internal/user/${userId}/${accountId}/`;

    axios.delete(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteUser = (userId, success, failure) => {
    const url = `/api/v1/internal/user/${userId}/`;

    axios.delete(url)
        .then(response => {
            success(userId);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (userId, image, success, failure) => {
    profileService.uploadImage(userId, image, success, failure);
};
