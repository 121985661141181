import axios from 'axios';

export const listCharges = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/charge/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createCharge = (charge, success, failure) => {
    const url = "/api/v1/internal/charge/";
    const money = charge.money || {};

    axios.post(url, {
        billingId: charge.billingId,
        reason: charge.reason,
        money: {
            dollars: money.dollars,
            cents: money.cents,
        },
        immediate: charge.immediate,
        recurring: charge.recurring,
        nextBillDue: charge.nextBillDue,
    })
        .then(response => {
            const json = response.data;

            success(json.charge, json.payment);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveCharge = (chargeId, charge, success, failure) => {
    const url = `/api/v1/internal/charge/${chargeId}/`;
    const money = charge.money || {};

    axios.put(url, {
        billingId: charge.billingId,
        reason: charge.reason,
        money: {
            dollars: money.dollars,
            cents: money.cents,
        },
        immediate: charge.immediate,
        recurring: charge.recurring,
        nextBillDue: charge.nextBillDue,
    })
        .then(response => {
            const json = response.data;

            success(json.charge, json.payment);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteCharge = (chargeId, success, failure) => {
    const url = "/api/v1/internal/charge/" + chargeId + "/";

    axios.delete(url)
        .then(response => {
            success(chargeId);
        })
        .catch(err => {
            failure(err);
        });
};
