import axios from 'axios';

export const listBillings = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/billing/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createBilling = (billing, success, failure) => {
    const url = "/api/v1/internal/billing/";

    axios.post(url, {
        name: billing.name,
        environment: billing.environment,
        status: billing.status,
        hidden: billing.hidden,
        userIds: billing.userIds,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveBilling = (billingId, billing, success, failure) => {
    const url = `/api/v1/internal/billing/${billingId}/`;

    axios.put(url, {
        name: billing.name,
        environment: billing.environment,
        status: billing.status,
        hidden: billing.hidden,
        userIds: billing.userIds,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteBilling = (billingId, success, failure) => {
    const url = "/api/v1/internal/billing/" + billingId + "/";

    axios.delete(url)
        .then(response => {
            success(billingId);
        })
        .catch(err => {
            failure(err);
        });
};
