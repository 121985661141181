import axios from 'axios';

export const createRecoveryCode = (email, success, failure) => {
    const url = "/api/v1/internal/code/recovery";

    axios.post(url, {
        email: email,
    })
        .then(response => {
            const json = response.data;

            success(json.code);
        })
        .catch(err => {
            failure(err);
        });
};

export const createLinkCode = (accountId, alias, success, failure) => {
    const url = "/api/v1/internal/code/link";

    axios.post(url, {
        accountId: accountId,
        alias: alias,
    })
        .then(response => {
            const json = response.data;

            success(json.code);
        })
        .catch(err => {
            failure(err);
        });
};
