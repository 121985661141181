import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {useFiltered} from "@atttomyx/shared-hooks";
import {IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon,} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import BillingFilters from "../../filters/billingFilters/billingFilters";
import BillingCard from "../../cards/billingCard/billingCard";
import AddBillingDialog from "../../dialogs/addBillingDialog/addBillingDialog";
import * as billingService from "../../services/internal/billings";
import {arrays} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {PAGE_BILLING} from "../../constants";

const BillingsPage = (props) => {
    const { history, snackbar, dimensions, billings, charges, filters, userRenderer, onSave, onDelete } = props;
    const [ billing, setBilling ] = useState(null);
    const [ chargesByBillingId, setChargesByBillingId ] = useState({});
    const [ showAdd, setShowAdd ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(billings, filters);

    useEffect(() => {
        setChargesByBillingId(arrays.groupByField(charges, "billingId"));
    }, [charges]);

    useEffect(() => {
        if (!billing) {
            setShowAdd(false);
            setShowDelete(false);
        }
    }, [billing]);

    return <div className="billings-page">
        <Typography variant="h5" paragraph={true}>
            Billings
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={BillingFilters}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(billing) =>
                <BillingCard key={billing.id} billing={billing} filters={filters} userRenderer={userRenderer}
                             charges={chargesByBillingId[billing.id] || []}>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => {
                                    setBilling(billing);
                                    setShowDelete(true);
                                }}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => router.redirectTo(history, PAGE_BILLING + "/" + billing.id)}>
                        <EditIcon/>
                    </IconButton>
                </BillingCard>}
        />
        <FloatingAddButton title="Add billing" position="higher"
                           onClick={() => {
                               setBilling({});
                               setShowAdd(true);
                           }}
        />
        {showAdd ? <AddBillingDialog
            snackbar={snackbar}
            onCancel={() => {
                setShowAdd(false);
                setBilling(null);
            }}
            onSave={(saved) => {
                setShowAdd(false);
                setBilling(null);
                onSave(saved);
            }}
        /> : null}
        {showDelete && billing ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={billing.name}
            onCancel={() => {
                setShowDelete(false);
                setBilling(null);
            }}
            onDelete={(billingId) => {
                setShowDelete(false);
                setBilling(null);
                onDelete(billingId);
            }}
            deleter={(success, failure) => billingService.deleteBilling(billing.id, success, failure)}
        /> : null}
    </div>
}

export default withRouter(BillingsPage);
