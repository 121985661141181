import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {Typography} from "@mui/material";
import OAuthLink from "../../components/oauthCode/oauthLink";
import {useQuery} from "@atttomyx/react-hooks";
import * as oauthService from "../../services/oauth";
import {router} from "@atttomyx/react-utils";
import {PAGE_OAUTH} from "../../constants";

const OAuthPage = (props) => {
    const { history, snackbar, integrations } = props;
    const query = useQuery();

    useEffect(() => {
        const code = query.get("code");
        const state = query.get("state");

        if (code && state) {
            const success = () => {
                snackbar.setSuccess("Access Token acquired!");
                router.redirectTo(history, PAGE_OAUTH);
            };

            const failure = (err) => {
                snackbar.setError(err);
                router.redirectTo(history, PAGE_OAUTH);
            };

            oauthService.saveTokens(state, code, success, failure);
        }
    }, []);

    return <div className="oauth">
        <Typography variant="h5" paragraph={true}>
            OAuth
        </Typography>
        {integrations.map(integration => <OAuthLink
            key={integration.id}
            title={integration.title}
            authUrl={integration.authUrl}
            scope={integration.scope}
            clientId={integration.clientId}
            redirectUrl={integration.redirectUrl}
            state={integration.id}
            pkce={integration.pkce}
            codeChallenge={integration.codeChallenge}
        />)}
    </div>
}

export default withRouter(OAuthPage);
