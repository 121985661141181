import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";
import {CATEGORY_STATUS_ACTIVE, CATEGORY_STATUS_DRAFT} from "../../constants";

const CategoryFilters = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="category-filters">
        <div className="field">
            <TextField label="Title"
                       type="text"
                       value={forms.sanitizeValue(data.title)}
                       onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Status"
                       select={true}
                       value={forms.sanitizeValue(data.status)}
                       onChange={(event) => onChange("status", event.target.value)}
            >
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value={CATEGORY_STATUS_ACTIVE}>Active</MenuItem>
                <MenuItem value={CATEGORY_STATUS_DRAFT}>Draft</MenuItem>
            </TextField>
        </div>
    </div>
}

export default CategoryFilters;
