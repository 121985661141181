import React, {useState} from "react";
import {CircularProgress, IconButton, TextField} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {Logo} from "@atttomyx/react-components";
import {forms, objects, validation} from "@atttomyx/shared-utils";
import {APP_LOGO_DEFAULT} from "../../constants";

const AppBrandingForm = (props) => {
    const { snackbar, appId, data, onChange : onSync, imageService, showImage } = props;
    const [ uploading, setUploading ] = useState(false);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        modified.valid = modified.title && modified.url
            && (!showImage || modified.logoUrl)
            && validation.validUrl(modified.url);

        onSync(modified);
    }

    const uploadFile = (event) => {
        const file = event.target.files[0];

        setUploading(true);

        const success = (logoUrl) => {
            setUploading(false);
            onChange("logoUrl", logoUrl);
        };

        const failure = (err) => {
            setUploading(false);
            snackbar.setError(err);
        };

        imageService.uploadImage(appId, file, success, failure);
    }

    const removeFile = () => {
        onChange("logoUrl", APP_LOGO_DEFAULT);
    }

    return <div className="app-branding-form">
        {showImage && appId ? <div className="field">
            {uploading ?
                <CircularProgress size="40px"/> :
                <>
                    <Logo
                        logoUrl={data.logoUrl}
                        size="large"
                    />
                    <IconButton color="secondary" title="Delete image"
                                disabled={data.logoUrl === APP_LOGO_DEFAULT}
                                onClick={removeFile}>
                        <DeleteIcon/>
                    </IconButton>
                    <input hidden
                           accept="image/*"
                           id="app-file"
                           type="file"
                           onChange={uploadFile}
                    />
                    <label htmlFor="app-file">
                        <IconButton color="primary" title="Upload image" component="span">
                            <EditIcon/>
                        </IconButton>
                    </label>
                </>}
        </div> : null}
        <div className="field">
            <TextField label="Name" required={true}
                       type="text"
                       value={forms.sanitizeValue(data.title)}
                       onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="URL" required={true}
                       type="url"
                       value={forms.sanitizeValue(data.url)}
                       error={!validation.validUrl(data.url)}
                       onChange={(event) => onChange("url", event.target.value)}
            />
        </div>
    </div>
}

export default AppBrandingForm;
