import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Divider, IconButton, Typography} from "@mui/material";
import {NavigateBefore as PrevIcon, NavigateNext as NextIcon} from "@mui/icons-material";
import {AccountAvatar, UserForm, VerticalDivider} from "@atttomyx/react-components";
import UserRoleForm from "../../forms/userRoleForm/userRoleForm";
import useSanitizedUsers from "../../hooks/sanitizedUsers";
import {useFiltered} from "@atttomyx/shared-hooks";
import * as userService from "../../services/internal/users";
import {arrays, forms, strings, users as userUtils} from "@atttomyx/shared-utils";
import {sanitizeUser, sortByNameAndAlias} from "../../utils/users";
import {verbiage} from "@atttomyx/shared-constants";
import {ACCOUNT_ID_INTERNAL, ACCOUNT_ID_PLATFORM} from "../../constants";
import "./editUserPage.css";

const isValid = (user) => strings.isNotBlank(user.id);

const toData = (user) => {
    return {
        imageUrl: user.imageUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        alias: user.alias,
        email: user.email,
        phone: user.phone,
        birthday: user.birthday,
        valid: isValid(user),
    }
};

const toRoles = (user) => {
    const alias = strings.isNotBlank(user.alias);

    return !alias ? user.roles || [] : null;
};

const EditUserPage = (props) => {
    const { snackbar, match, user : me, accounts, users : allUsers, filters, onSave, onPrev, onNext } = props;
    const [ account, setAccount ] = useState({})
    const [ user, setUser ] = useState({})
    const [ data, setData ] = useState(toData({}));
    const [ roles, setRoles ] = useState(toRoles({}));
    const [ saving, setSaving ] = useState(false);
    const [ prevId, setPrevId ] = useState(null);
    const [ nextId, setNextId ] = useState(null);
    const [ alias, setAlias ] = useState(false);

    const users = useSanitizedUsers(allUsers, filters);
    const filtered = useFiltered(users, filters, sortByNameAndAlias);

    const loadUser = (userId) => {
        const previousId = user.id;
        const accountId = match.params.accountId;
        const restrictedAccount = accountId === ACCOUNT_ID_INTERNAL;

        const found = arrays.findEntity(filtered, userId) || {};
        const ids = arrays.getPrevNextIds(filtered, found, restrictedAccount ? me.id : null);

        setUser(sanitizeUser(found, accountId));
        setPrevId(ids.prevId);
        setNextId(ids.nextId);

        if (previousId && previousId !== userId && found.id === userId) {
            snackbar.setInfo("Loaded " + userUtils.getFullName(found));
        }
    };

    const redirectToPrev = () => {
        const accountId = match.params.accountId;

        onPrev(accountId, prevId);
        loadUser(prevId);
    };

    const redirectToNext = () => {
        const accountId = match.params.accountId;

        onNext(accountId, nextId);
        loadUser(nextId);
    };

    useEffect(() => {
        const accountId = match.params.accountId;
        const userId = match.params.userId;

        loadUser(userId);
        setAccount(arrays.findEntity(accounts, accountId));
    }, [filtered]);

    useEffect(() => {
        setData(toData(user));
        setRoles(toRoles(user));
        setAlias(strings.isNotBlank(user.alias));
    }, [user]);

    const resetForm = () => {
        setData(toData(user));
        setRoles(toRoles(user));
    };

    const cleanForm = () => {
        setData(forms.cleanData(data));
    };

    const submitForm = () => {
        setSaving(true);

        const modified = {
            imageUrl: data.imageUrl,
            firstName: data.firstName,
            lastName: data.lastName,
            alias: alias ? data.alias : user.alias,
            email: data.email,
            phone: data.phone,
            birthday: data.birthday,
            roles: account ? roles : null,
        };

        const success = (user) => {
            const accountId = match.params.accountId;

            cleanForm();
            setSaving(false);

            snackbar.setSuccess("User saved");
            setUser(sanitizeUser(user, accountId));
            onSave(user);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        const accountId = account ? account.id : ACCOUNT_ID_PLATFORM;

        userService.saveUser(accountId, user.id, modified, success, failure);
    };

    const valid = data.valid;
    const modified = forms.differ(toData(user), data) || forms.differ(toRoles(user), roles);

    return saving ?
        <div className="edit-user-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-user-page">
            {account ?
                <>
                    <AccountAvatar account={account} mode="no-title"
                                   size={account.branding && account.branding.logoContainsName ? "plus" : "medium"}/>
                    {account.branding && !account.branding.logoContainsName ?
                        <Typography variant="caption">
                            {account.name}
                        </Typography> : null}
                    <Divider/>
                </> : null}
            <div className="cards">
                <div className="card">
                    <UserForm
                        snackbar={snackbar}
                        userId={user.id}
                        data={data}
                        onChange={setData}
                        showImage={true}
                        showPhone={true}
                        showBirthday={true}
                        useAlias={alias}
                        imageService={userService}
                    />
                </div>
                {roles && account ? <div className="card">
                    <UserRoleForm
                        roles={roles}
                        onChange={setRoles}
                    />
                </div> : null}
            </div>
            <div className="actions">
                <IconButton color="secondary" title="Previous user"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next user"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified || !user.id}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default EditUserPage;
