import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {forms} from "@atttomyx/shared-utils";
import {renderType} from "../../utils/apps";
import {
    APP_TYPE_INTERNAL,
    APP_TYPE_MOBILE_ONLY,
    APP_TYPE_MULTI_TENANT,
    APP_TYPE_PLATFORM,
    APP_TYPE_SINGLE_TENANT
} from "../../constants";

const AppTypeField = (props) => {
    const { value, onChange, required } = props;

    const renderItem = (type) => {
        return <MenuItem value={type}>{renderType(type)}</MenuItem>;
    };

    return <div className="app-type-field">
        <TextField label="Type"
                   select={true} required={required}
                   value={forms.sanitizeValue(value)}
                   onChange={(event) => onChange(event.target.value)}
                   SelectProps={{
                       renderValue: renderType,
                   }}
        >
            {!required ? <MenuItem value="">&nbsp;</MenuItem> : null}
            {renderItem(APP_TYPE_MULTI_TENANT)}
            {renderItem(APP_TYPE_SINGLE_TENANT)}
            {renderItem(APP_TYPE_MOBILE_ONLY)}
            {renderItem(APP_TYPE_INTERNAL)}
            {renderItem(APP_TYPE_PLATFORM)}
        </TextField>
    </div>
};

export default AppTypeField;
