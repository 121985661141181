import React, {useEffect, useState} from "react";
import {useFiltered} from "@atttomyx/shared-hooks";
import {IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon,} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import AdFilters from "../../filters/adFilters/adFilters";
import AdCard from "../../cards/adCard/adCard";
import AdDialog from "../../dialogs/adDialog/adDialog";
import * as adService from "../../services/internal/ads";
import {objects} from "@atttomyx/shared-utils";

const AdsPage = (props) => {
    const { snackbar, dimensions, apps, appRenderer, ads, filters, onSave, onDelete } = props;
    const [ ad, setAd ] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [ showEdit, setShowEdit ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(ads, filters);

    useEffect(() => {
        if (!ad) {
            setShowAdd(false);
            setShowEdit(false);
            setShowDelete(false);
        }
    }, [ad]);

    return <div className="ads-page">
        <Typography variant="h5" paragraph={true}>
            Ads
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={AdFilters}
                formProps={{
                    apps: apps,
                    appRenderer: appRenderer,
                }}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(ad) =>
                <AdCard key={ad.id} appRenderer={appRenderer} ad={ad} filters={filters}>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => {
                                    setAd(ad);
                                    setShowDelete(true);
                                }}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => {
                                    setAd(objects.deepCopy(ad));
                                    setShowEdit(true);
                                }}>
                        <EditIcon/>
                    </IconButton>
                </AdCard>}
        />
        <FloatingAddButton title="Add ad" position="higher"
                           onClick={() => {
                               setAd({});
                               setShowAdd(true);
                           }}
        />
        {(showAdd || showEdit) && ad ? <AdDialog
            snackbar={snackbar}
            apps={apps}
            appRenderer={appRenderer}
            ad={ad}
            onCancel={() => {
                setShowAdd(false);
                setShowEdit(false);
                setAd(null);
            }}
            onSave={(saved) => {
                setShowAdd(false);
                setShowEdit(false);
                setAd(null);
                onSave(saved);
            }}
        /> : null}
        {showDelete && ad ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={appRenderer.render(ad.appId)}
            onCancel={() => {
                setShowDelete(false);
                setAd(null);
            }}
            onDelete={(adId) => {
                setShowDelete(false);
                setAd(null);
                onDelete(adId);
            }}
            deleter={(success, failure) => adService.deleteAd(ad.id, success, failure)}
        /> : null}
    </div>
}

export default AdsPage;
