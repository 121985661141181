import axios from 'axios';
import axiosRetry from 'axios-retry';
import {strings} from "@atttomyx/shared-utils";
import {storage, timezones} from "@atttomyx/react-utils";
import {normalizeRoles} from "../utils/users";
import {time} from "@atttomyx/shared-constants";
import {APP_BASE_URL, APP_ID, KEY_AUTH_TOKEN, KEY_TEMPORARY_PASSWORD} from "../constants";

const local = storage.getLocal();
const session = storage.getSession();

let source = axios.CancelToken.source();

axios.defaults.timeout = time.SECONDS_IN_MINUTE * time.MILLISECONDS_IN_SECOND;
axios.defaults.baseURL = APP_BASE_URL;

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isSafeRequestError,
});

export const setupAxiosInterceptors = onUnauthenticated => {
    const onRequestSuccess = config => {
        const token = local.getStr(KEY_AUTH_TOKEN);
        const tz = timezones.getTimeZone();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (tz) {
            config.headers["x-tz"] = tz;
        }

        config.cancelToken = source.token;

        return config;
    };

    const onResponseSuccess = response => response;

    const onResponseError = err => {
        const status = err.status || (err.response ? err.response.status : 0);

        if (status === 403 || status === 401) {
            onUnauthenticated();
        }

        return Promise.reject(err);
    };

    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export const cancelAllRequests = (reason) => {
    source.cancel(reason);
    source = axios.CancelToken.source();
};

export const getLoggedInUser = (success, failure) => {
    axios.get("/api/v1/auth/user")
        .then(response => {
            const json = response.data;

            json.roles = normalizeRoles(json.roles);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const findAccounts = (success, failure) => {
    success([]);
};

export const login = (email, password, accountId, success, failure) => {
    axios.post("/api/v1/auth/login", {
        appId: APP_ID,
        accountId: accountId,
        email: email,
        password: password,
        rememberMe: true,
    })
        .then(response => {
            const bearerToken = response.headers.authorization;
            const json = response.data;

            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);

                clearAuthToken();
                storeAuthToken(jwt);
            }

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const stealthLogin = (appId, accountId, userId, success, failure) => {
    axios.post("/api/v1/internal/auth/stealth", {
        appId: appId,
        accountId: accountId,
        userId: userId,
    })
        .then(response => {
            const bearerToken = response.headers.authorization;

            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);

                success(jwt);

            } else {
                alert("no JWT?");
            }
        })
        .catch(err => {
            failure(err);
        });
};

export const changePassword = (existing, password, success, failure) => {
    axios.put("/api/v1/auth/password", {
        existing: existing,
        password: password,
    })
        .then(response => {
            success();
        })
        .catch(err => {
            failure(err);
        });
};

export const forgotPassword = (email, method, success, failure) => {
    axios.post("/api/v1/auth/forgot", {
        email: email,
        method: method,
    })
        .then(response => {
            success();
        })
        .catch(err => {
            failure(err);
        });
};

export const recoverPassword = (email, code, success, failure) => {
    // need to skip the request interceptors (don't want to redirect to login on 401)
    const instance = axios.create();

    instance.put("/api/v1/auth/recover", {
        email: email,
        code: code,
    })
        .then(response => {
            const json = response.data;

            success(json.password);
        })
        .catch(err => {
            failure(err);
        });
};

export const loggedIn = () => {
    let jwt = local.getStr(KEY_AUTH_TOKEN);

    return strings.isNotBlank(jwt);
};

const storeAuthToken = (jwt) => {
    local.setStr(KEY_AUTH_TOKEN, jwt);
};

export const clearAuthToken = () => {
    local.clear(KEY_AUTH_TOKEN);
};

export const getTemporaryPassword = () => {
    return session.getStr(KEY_TEMPORARY_PASSWORD);
};

export const storeTemporaryPassword = (temporaryPassword) => {
    session.setStr(KEY_TEMPORARY_PASSWORD, temporaryPassword);
};

export const clearTemporaryPassword = () => {
    session.clear(KEY_TEMPORARY_PASSWORD);
};
