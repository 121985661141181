import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {forms} from "@atttomyx/shared-utils";
import {FIELDS} from "../../constants";

const FIELD_OTHER = "other";

const AddRedactedFieldDialog = (props) => {
    const { onCancel, onAddField, onAddSetting } = props;
    const [ field, setField ] = useState(null);
    const [ other, setOther ] = useState(null);

    const submitForm = () => {
        if (field === FIELD_OTHER) {
            onAddSetting(other);

        } else {
            onAddField(field);
        }
    };

    return <Dialog
        open={true}
        aria-labelledby="add-redacted-field-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add Redacted Field
        </ClosableDialogTitle>
        <DialogContent className="add-redacted-field-dialog">
            <div className="field">
                <RadioGroup row={true}
                            onChange={(event) => setField(event.target.value)}>
                    {FIELDS.map(standard => <FormControlLabel
                        key={standard}
                        value={standard}
                        label={standard}
                        control={<Radio checked={field === standard} color="primary"/>}
                    />)}
                    <FormControlLabel
                        value={FIELD_OTHER}
                        label="Other"
                        control={<Radio checked={field === FIELD_OTHER} color="primary"/>}
                    />
                </RadioGroup>
            </div>
            {field === FIELD_OTHER ?
                <div className="field">
                    <TextField label="Other" required={true}
                               type="text"
                               value={forms.sanitizeValue(other)}
                               onChange={(event) => setOther(event.target.value)}
                    />
                </div> : null}
        </DialogContent>
        <DialogActions>
            <Button color="primary"
                    disabled={!field || (field === FIELD_OTHER && !other)}
                    onClick={submitForm}>
                Add
            </Button>
        </DialogActions>
    </Dialog>
}

export default AddRedactedFieldDialog;
