import React, {useState} from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle, ImageDialog} from "@atttomyx/react-components";
import {cloudinary, datetime, strings} from "@atttomyx/shared-utils";
import "./categoryCard.css";

const CategoryCard = (props) => {
    const { category, filters, children } = props;
    const [showDialog, setShowDialog] = useState(false);

    return <Card className="category-card" raised={true}>
        <CardContent>
            <CardTitle>
                <img
                    className="category"
                    alt={category.title}
                    src={category.imageUrl ? cloudinary.trimAndShrink(category.imageUrl) : "https://placehold.co/100x100"}
                    onClick={() => setShowDialog(strings.isNotBlank(category.imageUrl))}
                />
            </CardTitle>
            <CardData
                filters={filters}
                data={category}
                keyToMetadata={{
                    title: {
                        title: "Title",
                    },
                    uuidToImageUrl: {
                        title: "Images",
                        formatter: (map) => Object.keys(map).length,
                    },
                    status: {
                        title: "Status",
                        className: "status " + category.status,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
        {showDialog ?
            <ImageDialog
                title={category.title}
                imageUrl={category.imageUrl}
                onCancel={() => setShowDialog(false)}
            /> : null}
    </Card>
}

export default CategoryCard;
