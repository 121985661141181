export const sanitizeAccount = (account) => {
    account.branding = normalizeBranding(account.branding);
};

const normalizeBranding = (branding) => {
    return {
        logoUrl: branding.logoUrl,
        logoContainsName: branding.logoContainsName,
        primary: branding.primary || branding.primaryColor,
        secondary: branding.secondary || branding.secondaryColor,
    }
};

export const standardizeBranding = (branding) => {
    return {
        logoUrl: branding.logoUrl,
        logoContainsName: branding.logoContainsName,
        primaryColor: branding.primary,
        secondaryColor: branding.secondary,
    }
};
