export const listNotifications = (cursor, limit, success, failure) => {
    success({
        notifications: [],
        cursor: null,
    });
};

export const deleteNotifications = (notificationIds, success, failure) => {
    success([]);
};

export const loadPreferences = function(success, failure) {
    success({
        messagingToken: null,
        deliveries: [],
        topics: [],
    });
};

export const savePreferences = (preferences, success, failure) => {
    success({
        messagingToken: null,
        deliveries: [],
        topics: [],
    });
};
