import {misc} from "@atttomyx/shared-constants";
import {
    APP_TYPE_INTERNAL,
    APP_TYPE_MOBILE_ONLY,
    APP_TYPE_MULTI_TENANT,
    APP_TYPE_PLATFORM,
    APP_TYPE_SINGLE_TENANT
} from "../constants";

export const renderVersion = (version) => `${version.major}.${version.minor}.${version.patch}`;

export const renderType = (type) => {
    let ret = misc.UNKNOWN;

    if (type) {
        switch (type) {
            case APP_TYPE_MULTI_TENANT:
                ret = "Multi-tenant";
                break;
            case APP_TYPE_SINGLE_TENANT:
                ret = "Single-tenant";
                break;
            case APP_TYPE_MOBILE_ONLY:
                ret = "Mobile-only";
                break;
            case APP_TYPE_INTERNAL:
                ret = "Internal";
                break;
            case APP_TYPE_PLATFORM:
                ret = "Platform";
                break;
            default:
                ret = misc.UNKNOWN;
                break;
        }
    }

    return ret;
};
