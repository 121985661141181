import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime, formatters} from "@atttomyx/shared-utils";
import {getPaymentStatus} from "../../utils/charges";
import {sortFullName} from "../../utils/users";
import {BILLING_STATUS_ACTIVE} from "../../constants";

const BillingCard = (props) => {
    const { filters, billing, charges, userRenderer, children } = props;
    const [ paymentStatus, setPaymentStatus ] = useState(BILLING_STATUS_ACTIVE);

    useEffect(() => {
        setPaymentStatus(getPaymentStatus(charges));
    }, [charges]);

    return <Card className="billing-card" raised={true}>
        <CardContent>
            <CardTitle>
                {billing.name}
            </CardTitle>
            <CardData
                filters={filters}
                data={billing}
                keyToMetadata={{
                    userIds: {
                        title: "Owners",
                        formatter: (data) => data.map(userRenderer.render).sort(sortFullName),
                        hidden: billing.userIds.length === 0,
                    },
                    charges: {
                        title: "Charges",
                        formatter: formatters.arrayLength,
                        getter: (data) => charges,
                    },
                    status: {
                        title: "Environment",
                        className: "status " + billing.status,
                        hidden: !billing.environment,
                    },
                    payment: {
                        title: "Payment",
                        className: "status " + paymentStatus,
                        getter: (data) => paymentStatus,
                        hidden: charges.length === 0,
                    },
                    hidden: {
                        title: "Hidden",
                        formatter: formatters.booleanToString,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default BillingCard;
