import axios from "axios";
import {CLOUDINARY_ID} from "../../constants";

export const listAds = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/ad/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const addAd = (ad, success, failure) => {
    const url = `/api/v1/internal/ad/`;

    axios.post(url, {
        appId: ad.appId,
        imageUrl: ad.imageUrl,
        clickUrl: ad.clickUrl,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const updateAd = (adId, ad, success, failure) => {
    const url = `/api/v1/internal/ad/${adId}/`;

    axios.put(url, {
        appId: ad.appId,
        imageUrl: ad.imageUrl,
        clickUrl: ad.clickUrl,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteAd = (adId, success, failure) => {
    const url = `/api/v1/internal/ad/${adId}/`;

    axios.delete(url)
        .then(response => {
            success(adId);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (appId, image, success, failure) => {
    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_ID}/upload`;

    const data = new FormData();
    data.append("upload_preset", "app_image");
    data.append("file", image);
    data.append("tags", appId);

    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(response => {
        success(response.data.secure_url);
    })
    .catch(err => {
        failure(err);
    });
};
