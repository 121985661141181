import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {notifications} from "@atttomyx/shared-constants";

const AppNotificationForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.defaultDelivery);

        onSync(modified);
    }

    return <div className="app-notification-form">
        <div className="field">
            <TextField label="Default delivery"
                       required={true}
                       select={true}
                       value={forms.sanitizeValue(data.defaultDelivery)}
                       onChange={(event) => onChange("defaultDelivery", event.target.value)}
            >
                <MenuItem value={notifications.DELIVERY_NONE}>None</MenuItem>
                <MenuItem value={notifications.DELIVERY_MOBILE}>Mobile</MenuItem>
                <MenuItem value={notifications.DELIVERY_APP}>App</MenuItem>
                <MenuItem value={notifications.DELIVERY_EMAIL}>Email</MenuItem>
                <MenuItem value={notifications.DELIVERY_SMS}>SMS</MenuItem>
            </TextField>
        </div>
        <div className="field">
            <TextField label="Project Id"
                       type="text"
                       value={forms.sanitizeValue(data.projectId)}
                       onChange={(event) => onChange("projectId", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Service Account JSON"
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.authJson)}
                       onChange={(event) => onChange("authJson", event.target.value)}
            />
        </div>
    </div>
}

export default AppNotificationForm;
