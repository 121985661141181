import axios from "axios";
import {CLOUDINARY_ID} from "../../constants";

export const listApps = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/app/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const addApp = (app, success, failure) => {
    const url = `/api/v1/internal/app/`;

    axios.post(url, {
        title: app.title,
        url: app.url,
        logoUrl: app.logoUrl,
        type: app.type,
        welcomeAdmin: app.welcomeAdmin,
        welcomeUser: app.welcomeUser,
        notifications: {
            defaultDelivery: app.defaultDelivery,
            projectId: app.projectId,
            authJson: app.authJson,
        },
        redactedFields: app.redactedFields,
        redactedSettings: app.redactedSettings,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const updateApp = (appId, app, success, failure) => {
    const url = `/api/v1/internal/app/${appId}/`;

    axios.put(url, {
        title: app.title,
        url: app.url,
        logoUrl: app.logoUrl,
        type: app.type,
        welcomeAdmin: app.welcomeAdmin,
        welcomeUser: app.welcomeUser,
        notifications: {
            defaultDelivery: app.defaultDelivery,
            projectId: app.projectId,
            authJson: app.authJson,
        },
        redactedFields: app.redactedFields,
        redactedSettings: app.redactedSettings,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteApp = (appId, success, failure) => {
    const url = `/api/v1/internal/app/${appId}/`;

    axios.delete(url)
        .then(response => {
            success(appId);
        })
        .catch(err => {
            failure(err);
        });
};

export const releaseApp = (appId, releaseType, success, failure) => {
    const url = `/api/v1/internal/app/${appId}/release/${releaseType}/`;

    axios.put(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (appId, logo, success, failure) => {
    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_ID}/upload`;

    const data = new FormData();
    data.append("upload_preset", "app_image");
    data.append("file", logo);
    data.append("tags", appId);

    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(response => {
        success(response.data.secure_url);
    })
    .catch(err => {
        failure(err);
    });
};
