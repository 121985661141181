import {BILLING_STATUS_ACTIVE, BILLING_STATUS_DISABLED, CHARGE_STATUS_FAILURE} from "../constants";
import {sorting} from "@atttomyx/shared-utils";

export const getPaymentStatus = (charges) => {
    const failure = charges.find(charge => charge.status === CHARGE_STATUS_FAILURE && !charge.nextBillDue);

    return failure ? BILLING_STATUS_DISABLED : BILLING_STATUS_ACTIVE;
};

export const sortByNextBillDueAndReason = (a, b) => {
    return sorting.sortByField(a, b, "nextBillDue");
};
