import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import AddUserForm from "../../forms/addUserForm/addUserForm";
import * as userService from "../../services/internal/users";
import {forms} from "@atttomyx/shared-utils";
import {renderUser} from "../../utils/users";

const freshData = () => {
    return {
        accountId: null,
        userId: null,
        valid: false,
    }
};

const AddToAccountDialog = (props) => {
    const {snackbar, accountId, accounts, accountRenderer, users, onCancel, onSave} = props;
    const [data, setData] = useState(freshData());
    const [saving, setSaving] = useState(false);

    const accountName = accountRenderer.render(accountId);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(renderUser(saved) + " added to " + accountName);
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        userService.addToAccount(data.userId, accountId, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="add-to-account-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add User to {accountName}
        </ClosableDialogTitle>
        <DialogContent className="add-to-account-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <AddUserForm
                    accountId={accountId}
                    accounts={accounts}
                    accountRenderer={accountRenderer}
                    users={users}
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AddToAccountDialog;
