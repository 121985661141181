import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle, UserForm} from "@atttomyx/react-components";
import * as userService from "../../services/internal/users";
import {forms} from "@atttomyx/shared-utils";
import {ACCOUNT_ID_PLATFORM} from "../../constants";

const freshData = () => {
    return {
        imageUrl: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        birthday: null,
        valid: false,
    }
};

const AddUserDialog = (props) => {
    const { snackbar, onCancel, onSave } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess("User saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const added = {
            imageUrl: data.imageUrl,
            firstName: data.firstName,
            lastName: data.lastName,
            alias: data.alias,
            email: data.email,
            phone: data.phone,
            birthday: data.birthday,
        };

        userService.createUser(ACCOUNT_ID_PLATFORM, added, success, failure);
    };

    return <Dialog
        open={true}
        aria-labelledby="add-user-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add User
        </ClosableDialogTitle>
        <DialogContent className="add-user-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <UserForm
                    snackbar={snackbar}
                    data={data}
                    onChange={setData}
                    showImage={false}
                    showPhone={true}
                    showBirthday={true}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AddUserDialog;
