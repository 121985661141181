import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {Logo} from "@atttomyx/react-components";
import {forms} from "@atttomyx/shared-utils";
import "./appField.css";

const AppField = (props) => {
    const { apps, appRenderer, value, onChange, required, multiple, label } = props;

    return <div className="app-field">
        <TextField label={label || (multiple ? "Apps" : "App")}
                   required={required}
                   select={true}
                   value={multiple ? forms.sanitizeMulti(value) : forms.sanitizeValue(value)}
                   onChange={(event) => onChange(event.target.value)}
                   SelectProps={{
                       multiple: multiple,
                       renderValue: (value) => appRenderer.render(value),
                   }}
        >
            {!multiple ? <MenuItem value="">&nbsp;</MenuItem> : null}
            {apps.map((app) =>
                    <MenuItem key={app.id} value={app.id}>
                    <span className="app-field-logo">
                        <Logo logoUrl={app.logoUrl} size="tiny"/>
                    </span>
                    {app.title}
                    </MenuItem>)}
        </TextField>
    </div>
};

export default AppField;
