import React, {useState} from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {ImageDialog} from "@atttomyx/react-components";
import {cloudinary, strings} from "@atttomyx/shared-utils";
import "./imageCard.css";

const ImageCard = (props) => {
    const { image, children } = props;
    const [showDialog, setShowDialog] = useState(false);

    return <Card className="image-card" raised={true}>
        <CardContent>
            <img
                className="image"
                alt={image.uuid}
                src={image.imageUrl ? cloudinary.trimAndShrink(image.imageUrl) : "https://placehold.co/1024x1024"}
                onClick={() => setShowDialog(strings.isNotBlank(image.imageUrl))}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
        {showDialog ?
            <ImageDialog
                title={image.uuid}
                imageUrl={image.imageUrl}
                onCancel={() => setShowDialog(false)}
            /> : null}
    </Card>
}

export default ImageCard;
