import axios from 'axios';

export const listPayments = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/payment/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};
