import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {forms} from "@atttomyx/shared-utils";
import {roles} from "@atttomyx/shared-constants";

const ROLE_OTHER = "other";

const AddRoleDialog = (props) => {
    const { onCancel, onAdd } = props;
    const [ role, setRole ] = useState(null);
    const [ other, setOther ] = useState(null);

    const submitForm = () => {
        onAdd(role === ROLE_OTHER ? other : role);
    };

    return <Dialog
        open={true}
        aria-labelledby="add-role-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add Role
        </ClosableDialogTitle>
        <DialogContent className="add-role-dialog">
            <div className="field">
                <RadioGroup row={true}
                            onChange={(event) => setRole(event.target.value)}>
                    <FormControlLabel
                        value={roles.ADMIN}
                        label="Admin"
                        control={<Radio checked={role === roles.ADMIN} color="primary"/>}
                    />
                    <FormControlLabel
                        value={ROLE_OTHER}
                        label="Other"
                        control={<Radio checked={role === ROLE_OTHER} color="primary"/>}
                    />
                </RadioGroup>
            </div>
            {role === ROLE_OTHER ?
                <div className="field">
                    <TextField label="Other" required={true}
                               type="text"
                               value={forms.sanitizeValue(other)}
                               onChange={(event) => setOther(event.target.value)}
                    />
                </div> : null}
        </DialogContent>
        <DialogActions>
            <Button color="primary"
                    disabled={!role || (role === ROLE_OTHER && !other)}
                    onClick={submitForm}>
                Add
            </Button>
        </DialogActions>
    </Dialog>
}

export default AddRoleDialog;
