import {withRouter} from "react-router-dom";
import {Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {
    CheckBoxOutlined as OptionsIcon,
    Collections as CategoriesIcon,
    Face as ProfileIcon,
    Fingerprint as PasswordIcon,
    Info as AboutIcon,
    Login as LoginIcon,
    Logout as LogoutIcon,
    Panorama as AdsIcon,
    PrecisionManufacturing as RobotsIcon,
} from "@mui/icons-material";
import {confirm, mobile, router} from "@atttomyx/react-utils";
import {
    PAGE_ABOUT,
    PAGE_ADS,
    PAGE_CATEGORIES,
    PAGE_OAUTH,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_PROFILE,
    PAGE_ROBOTS
} from "../../constants";

const MoreMenu = (props) => {
    const { history, user, onCancel, onLogout } = props;

    return <Drawer anchor="right"
                   open={true}
                   onClick={onCancel}>
        <List>
            {user.roles.robots ?
                <ListItemButton onClick={() => router.redirectTo(history, PAGE_ROBOTS)}>
                    <ListItemIcon><RobotsIcon/></ListItemIcon>
                    <ListItemText>Robots</ListItemText>
                </ListItemButton> : null}
            {user.roles.ads ?
                <ListItemButton onClick={() => router.redirectTo(history, PAGE_ADS)}>
                    <ListItemIcon><AdsIcon/></ListItemIcon>
                    <ListItemText>Ads</ListItemText>
                </ListItemButton> : null}
            {user.roles.reveal ?
                <ListItemButton onClick={() => router.redirectTo(history, PAGE_CATEGORIES)}>
                    <ListItemIcon><CategoriesIcon/></ListItemIcon>
                    <ListItemText>Categories</ListItemText>
                </ListItemButton> : null}
            {user.roles.reveal ?
                <ListItemButton onClick={() => router.redirectTo(history, PAGE_OAUTH)}>
                    <ListItemIcon><LoginIcon/></ListItemIcon>
                    <ListItemText>OAuth</ListItemText>
                </ListItemButton> : null}
            {user.roles.robots || user.roles.ads || user.roles.reveal ?
                <Divider/> : null}
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_PROFILE)}>
                <ListItemIcon><ProfileIcon/></ListItemIcon>
                <ListItemText>Profile</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_OPTIONS)}>
                <ListItemIcon><OptionsIcon/></ListItemIcon>
                <ListItemText>Options</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_PASSWORD)}>
                <ListItemIcon><PasswordIcon/></ListItemIcon>
                <ListItemText>Password</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => confirm.confirm(mobile.isMobile()
                ? "Someone would need to have physical access to your phone to access this account. Are you sure you want to logout?"
                : "Are you sure you want to logout?", onLogout)}>
                <ListItemIcon><LogoutIcon/></ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </ListItemButton>
            <Divider/>
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_ABOUT)}>
                <ListItemIcon><AboutIcon/></ListItemIcon>
                <ListItemText>About</ListItemText>
            </ListItemButton>
        </List>
    </Drawer>
};

export default withRouter(MoreMenu);
