import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";
import {RELEASE_TYPE_MAJOR, RELEASE_TYPE_MINOR, RELEASE_TYPE_PATCH} from "../../constants";

const ReleaseForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = modified.releaseType;

        onSync(modified);
    }

    return <div className="release-form">
        <div className="field top-margin">
            <TextField label="Release Type" select={true} required={true}
                       value={forms.sanitizeValue(data.releaseType)}
                       onChange={(event) => onChange("releaseType", event.target.value)}
            >
                <MenuItem key={RELEASE_TYPE_MAJOR} value={RELEASE_TYPE_MAJOR}>Major</MenuItem>
                <MenuItem key={RELEASE_TYPE_MINOR} value={RELEASE_TYPE_MINOR}>Minor</MenuItem>
                <MenuItem key={RELEASE_TYPE_PATCH} value={RELEASE_TYPE_PATCH}>Patch</MenuItem>
            </TextField>
        </div>
    </div>
}

export default ReleaseForm;
