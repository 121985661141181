import axios from 'axios';

export const listRobots = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/robot/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveRobot = (robot, success, failure) => {
    const url = "/api/v1/internal/robot/";

    axios.post(url, {
        name: robot.name,
        email: robot.email,
        password: robot.password,
        appIds: robot.appIds,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteRobot = (robotId, success, failure) => {
    const url = "/api/v1/internal/robot/" + robotId + "/";

    axios.delete(url)
        .then(response => {
            success(robotId);
        })
        .catch(err => {
            failure(err);
        });
};
