import React from "react";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import MoneyForm from "../moneyForm/moneyForm";
import {datetime, forms, objects, strings} from "@atttomyx/shared-utils";
import {CHARGE_IMMEDIATE, CHARGE_RECURRING, CHARGES} from "../../constants";
import "./chargeForm.css";

const ChargeForm = (props) => {
    const { data, onChange : onSync } = props;
    const today = datetime.today();

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        modified.valid = modified.reason
            && (modified.type === CHARGE_IMMEDIATE || (modified.nextBillDue && datetime.isAfter(modified.nextBillDue, today)))
            && modified.money.valid;

        onSync(modified);
    }

    return <div className="charge-form">
        <div className="field top-margin">
            <TextField label="Reason" required={true}
                       type="text"
                       value={forms.sanitizeValue(data.reason)}
                       onChange={(event) => onChange("reason", event.target.value)}
            />
        </div>
        <MoneyForm
            data={data.money}
            onChange={(money) => onChange("money", money)}
        />
        <div className="option">
            <RadioGroup row={true}
                        onChange={(event) => onChange("type", event.target.value)}>
                {CHARGES.map(type => <FormControlLabel
                    key={type}
                    value={type}
                    label={type}
                    control={<Radio checked={data.type === type} color="primary"/>}
                />)}
            </RadioGroup>
        </div>
        {data.type !== CHARGE_IMMEDIATE ?
            <div className="field">
                <TextField label={data.type === CHARGE_RECURRING ? "Next Due" : "Due"} required={true}
                           type="date"
                           value={forms.sanitizeValue(data.nextBillDue)}
                           error={strings.isNotBlank(data.nextBillDue) && !datetime.isAfter(data.nextBillDue, today)}
                           onChange={(event) => onChange("nextBillDue", event.target.value)}
                />
            </div> : null}
    </div>
}

export default ChargeForm;
