import axios from "axios";
import {CLOUDINARY_ID} from "../../constants";

export const listCategories = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/category/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createCategory = (category, success, failure) => {
    const url = `/api/v1/internal/category/`;

    axios.post(url, {
        status: category.status,
        title: category.title,
        imageUrl: category.imageUrl,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const updateCategory = (categoryId, category, success, failure) => {
    const url = `/api/v1/internal/category/${categoryId}/`;

    axios.put(url, {
        status: category.status,
        title: category.title,
        imageUrl: category.imageUrl,
        uuidToImageUrl: category.uuidToImageUrl,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteCategory = (categoryId, success, failure) => {
    const url = `/api/v1/internal/category/${categoryId}/`;

    axios.delete(url)
        .then(response => {
            success(categoryId);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (categoryId, image, success, failure) => {
    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_ID}/upload`;

    const data = new FormData();
    data.append("upload_preset", "category_image");
    data.append("file", image);
    data.append("tags", categoryId);

    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(response => {
        const json = response.data;

        success(json.asset_id, json.secure_url);
    })
    .catch(err => {
        failure(err);
    });
};
