import React from "react";
import "./bannerAd.css";

const BannerAd = (props) => {
    const { imageUrl } = props;

    return <div className="banner-ad">
        <img
            alt="banner ad"
            src={imageUrl || "https://placehold.co/728x90"}
        />
    </div>
};

export default BannerAd;
