import React from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime, formatters} from "@atttomyx/shared-utils";
import {CHARGE_STATUS_FAILURE, CHARGE_STATUS_SUCCESS} from "../../constants";

const PaymentCard = (props) => {
    const { payment, children } = props;

    return <Card className="charge-card" raised={true}>
        <CardContent>
            <CardTitle>
                {datetime.getFullDateAndTime(payment.created)}
            </CardTitle>
            <CardData
                data={payment}
                keyToMetadata={{
                    money: {
                        title: "Amount",
                        formatter: formatters.usDollars,
                    },
                    success: {
                        title: "Status",
                        className: "status " + (payment.success ? CHARGE_STATUS_SUCCESS : CHARGE_STATUS_FAILURE),
                        getter: (data) => data.success ? CHARGE_STATUS_SUCCESS : CHARGE_STATUS_FAILURE,
                    },
                    errors: {
                        title: "Errors",
                        hidden: payment.success,
                    },
                    reasons: {
                        title: "Charges",
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default PaymentCard;
