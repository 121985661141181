import React, {useEffect, useState} from "react";
import {useFiltered} from "@atttomyx/shared-hooks";
import {IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon, Fingerprint as PasswordIcon,} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import RobotFilters from "../../filters/robotFilters/robotFilters";
import RobotCard from "../../cards/robotCard/robotCard";
import RobotDialog from "../../dialogs/robotDialog/robotDialog";
import * as robotService from "../../services/internal/robots";
import {objects} from "@atttomyx/shared-utils";

const RobotsPage = (props) => {
    const { snackbar, dimensions, apps, appRenderer, robots, logins, filters, onSave, onDelete } = props;
    const [ robot, setRobot ] = useState(null);
    const [ userIdToLogin, setUserIdToLogin ] = useState({});
    const [ showAdd, setShowAdd ] = useState(false);
    const [ showEdit, setShowEdit ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(robots, filters);

    useEffect(() => {
        if (!robot) {
            setShowAdd(false);
            setShowEdit(false);
            setShowPassword(false);
            setShowDelete(false);
        }
    }, [robot]);

    useEffect(() => {
        const loginsByUserId = {};

        logins
            .forEach(login => {
                loginsByUserId[login.userId] = login;
            });

        setUserIdToLogin(loginsByUserId);
    }, [logins]);

    return <div className="robots-page">
        <Typography variant="h5" paragraph={true}>
            Robots
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={RobotFilters}
                formProps={{
                    apps: apps,
                    appRenderer: appRenderer,
                }}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(robot) =>
                <RobotCard key={robot.id} appRenderer={appRenderer} robot={robot} filters={filters} userIdToLogin={userIdToLogin}>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => {
                                    setRobot(robot);
                                    setShowDelete(true);
                                }}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Change password"
                                onClick={() => {
                                    setRobot(objects.deepCopy(robot));
                                    setShowPassword(true);
                                }}>
                        <PasswordIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => {
                                    setRobot(objects.deepCopy(robot));
                                    setShowEdit(true);
                                }}>
                        <EditIcon/>
                    </IconButton>
                </RobotCard>}
        />
        <FloatingAddButton title="Add robot" position="higher"
                           onClick={() => {
                               setRobot({});
                               setShowAdd(true);
                           }}
        />
        {(showAdd || showEdit || showPassword) && robot ? <RobotDialog
            snackbar={snackbar}
            apps={apps}
            appRenderer={appRenderer}
            robot={robot}
            onCancel={() => {
                setShowAdd(false);
                setShowEdit(false);
                setShowPassword(false);
                setRobot(null);
            }}
            onSave={(saved) => {
                setShowAdd(false);
                setShowEdit(false);
                setShowPassword(false);
                setRobot(null);
                onSave(saved);
            }}
            showFields={showAdd || showEdit}
            showPassword={showAdd || showPassword}
        /> : null}
        {showDelete && robot ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={robot.name}
            onCancel={() => {
                setShowDelete(false);
                setRobot(null);
            }}
            onDelete={(robotId) => {
                setShowDelete(false);
                setRobot(null);
                onDelete(robotId);
            }}
            deleter={(success, failure) => robotService.deleteRobot(robot.id, success, failure)}
        /> : null}
    </div>
}

export default RobotsPage;
