import {arrays, objects, sorting, users as userUtils, users} from "@atttomyx/shared-utils";
import {misc, themes} from "@atttomyx/shared-constants";
import {
    ROLE_ACCOUNTS,
    ROLE_ADS,
    ROLE_APPS,
    ROLE_BILLING,
    ROLE_DATA_MIGRATION,
    ROLE_REVEAL,
    ROLE_ROBOTS,
    ROLE_USERS
} from "../constants";

export const sortByNameAndAlias = sorting.getCompositeSorter([users.sortByAlias, users.sortByName]);

export const sortFullName = (a, b) => {
    const userA = toUser(a);
    const userB = toUser(b);

    return userUtils.sortByName(userA, userB);
};

const toUser = (fullName) => {
    const split = fullName ? fullName.split(" ") : ["", ""];

    return split.length === 2
        ? {
            firstName: split[0],
            lastName: split[1],
        } :
        {
            firstName: fullName || "",
            lastName: "",
        }
};

export const sanitizeProfile = (profile) => {
    profile.settings = sanitizeSettings(profile.settings);
};

const sanitizeSettings = (settings) => {
    const sanitized = settings || {};

    sanitized.theme = objects.defaultIfNullOrUndefined(sanitized.theme, themes.LIGHT);

    return sanitized;
};

export const normalizeRoles = (roles) => {
    const sanitized = roles || [];
    const normalized = users.normalizeRoles(sanitized);

    normalized.apps = arrays.contains(sanitized, ROLE_APPS);
    normalized.accounts = arrays.contains(sanitized, ROLE_ACCOUNTS);
    normalized.users = arrays.contains(sanitized, ROLE_USERS);
    normalized.robots = arrays.contains(sanitized, ROLE_ROBOTS);
    normalized.billing = arrays.contains(sanitized, ROLE_BILLING);
    normalized.dataMigration = arrays.contains(sanitized, ROLE_DATA_MIGRATION);
    normalized.ads = arrays.contains(sanitized, ROLE_ADS);
    normalized.reveal = arrays.contains(sanitized, ROLE_REVEAL);

    return normalized;
};

export const isInAccount = (user, accountId) => {
    return misc.NONE === accountId
        ? !user.accountIds || user.accountIds.length === 0
        : arrays.contains(user.accountIds, accountId);
};

export const sanitizeUser = (user, accountId) => {
    const sanitized = objects.deepCopy(user);

    sanitized.alias = getAlias(user, accountId);
    sanitized.installationIds = getInstallationIds(user, accountId);
    sanitized.roles = getRoles(user, accountId);

    return sanitized;
};

const getAlias = (user, accountId) => {
    const accountIdToAlias = user.accountIdToAlias || {};

    return accountIdToAlias[accountId];
};

const getInstallationIds = (user, accountId) => {
    const accountIdToInstallationIds = user.accountIdToInstallationIds || {};

    return accountIdToInstallationIds[accountId] || [];
};

const getRoles = (user, accountId) => {
    const accountIdToRoles = user.accountIdToRoles || {};

    return (accountIdToRoles[accountId] || []).sort(sorting.alphabetize);
};

export const renderUser = (user) => user.alias ? user.alias : userUtils.getFullName(user);
