import React from "react";
import {Checkbox, FormControlLabel, MenuItem, TextField, Typography} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";
import {BILLING_STATUS_ACTIVE, BILLING_STATUS_DISABLED} from "../../constants";

const BillingForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        modified.valid = modified.name && modified.status;

        onSync(modified);
    }

    return <div className="billing-form">
        <Typography variant="h5">
            Billing
        </Typography>
        <div className="field top-margin">
            <TextField label="Name" required={true}
                       type="text"
                       value={forms.sanitizeValue(data.name)}
                       onChange={(event) => onChange("name", event.target.value)}
            />
        </div>
        <div className="option">
            <FormControlLabel labelPlacement="end"
                              label="Has own cloud environment"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.environment)}
                                                 onChange={(event) => onChange("environment", event.target.checked)}
                              />}
            />
        </div>
        {data.environment ? <div className="field">
            <TextField label="Status"
                       select={true} required={true}
                       value={forms.sanitizeValue(data.status)}
                       onChange={(event) => onChange("status", event.target.value)}
            >
                <MenuItem value={BILLING_STATUS_ACTIVE}>Active</MenuItem>
                <MenuItem value={BILLING_STATUS_DISABLED}>Disabled</MenuItem>
            </TextField>
        </div> : null}
        <div className="option">
            <FormControlLabel labelPlacement="end"
                              label="Hidden"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.hidden)}
                                                 onChange={(event) => onChange("hidden", event.target.checked)}
                              />}
            />
        </div>
    </div>
}

export default BillingForm;
