import React from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime, formatters} from "@atttomyx/shared-utils";

const ChargeCard = (props) => {
    const { charge, children } = props;

    return <Card className="charge-card" raised={true}>
        <CardContent>
            <CardTitle>
                {charge.reason}
            </CardTitle>
            <CardData
                data={charge}
                keyToMetadata={{
                    money: {
                        title: "Amount",
                        formatter: (money) => {
                            const rendered = formatters.usDollars(money);

                            return charge.recurring ? `${rendered} / month` : rendered;
                        },
                    },
                    nextBillDue: {
                        title: charge.recurring ? "Next Due" : "Due",
                        formatter: (data) => data ? datetime.getFullDate(data) : charge.immediate ? "Now" : "N/A",
                    },
                    status: {
                        title: "Status",
                        className: "status " + charge.status,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default ChargeCard;
