import React, {useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Typography} from "@mui/material";
import {BrandingForm, JoinCode} from "@atttomyx/react-components";
import AccountForm from "../../forms/accountForm/accountForm";
import * as accountService from "../../services/internal/accounts";
import {forms} from "@atttomyx/shared-utils";
import {branding} from "@atttomyx/react-utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./addAccountPage.css";

const freshData = () => {
    return {
        appId: null,
        billingId: null,
        disabled: false,
        allowJoin: false,
        valid: false,
    }
};

const freshBranding = () => {
    return {
        name: null,
        website: null,
        logoUrl: null,
        logoContainsName: false,
        primary: branding.BRANDING_LIGHT.primary,
        secondary: branding.BRANDING_LIGHT.secondary,
        valid: false,
    }
};

const AddAccountPage = (props) => {
    const {snackbar, apps, appRenderer, billingRenderer, onSaveAccount, onSaveBilling} = props;
    const [data, setData] = useState(freshData());
    const [branding, setBranding] = useState(freshBranding());
    const [saving, setSaving] = useState(false);

    const resetForm = () => {
        setData(freshData());
        setBranding(freshBranding());
    }

    const submitForm = () => {
        setSaving(true);

        const modified = {
            appId: data.appId,
            billingId: data.billingId,
            name: branding.name,
            website: branding.website,
            branding: {
                logoUrl: branding.logoUrl,
                logoContainsName: branding.logoContainsName,
                primary: branding.primary,
                secondary: branding.secondary,
            },
            disabled: data.disabled,
            allowJoin: data.allowJoin,
        };

        const success = (account) => {
            resetForm();
            setSaving(false);

            snackbar.setSuccess("Account saved");
            onSaveAccount(account);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        accountService.addAccount(modified, success, failure);
    }

    const valid = data.valid && branding.valid;
    const modified = forms.differ(freshData(), data)
        || forms.differ(freshBranding(), branding);

    return saving ?
        <div className="add-account-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="add-account-page">
            <div className="cards">
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        Branding
                    </Typography>
                    <BrandingForm
                        data={branding}
                        onChange={setBranding}
                        showImage={false}
                    />
                </div>
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        General
                    </Typography>
                    <AccountForm
                        snackbar={snackbar}
                        apps={apps}
                        appRenderer={appRenderer}
                        billingRenderer={billingRenderer}
                        data={data}
                        onChange={setData}
                        onSaveBilling={onSaveBilling}
                    />
                </div>
            </div>
            <div className="actions">
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
            <JoinCode allowJoin={data.allowJoin} code={null}/>
        </div>
}

export default AddAccountPage;
