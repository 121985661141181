import React from "react";
import {TextField} from "@mui/material";
import {BoolFilterField, CountFilterField} from "@atttomyx/react-components";
import {forms, objects} from "@atttomyx/shared-utils";

const UserFilters = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="user-filters">
        <div className="field">
            <TextField label="First Name"
                       type="text"
                       value={forms.sanitizeValue(data.firstName)}
                       onChange={(event) => onChange("firstName", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Last Name"
                       type="text"
                       value={forms.sanitizeValue(data.lastName)}
                       onChange={(event) => onChange("lastName", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Alias"
                       type="text"
                       value={forms.sanitizeValue(data.alias)}
                       onChange={(event) => onChange("alias", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Email"
                       type="text"
                       value={forms.sanitizeValue(data.email)}
                       onChange={(event) => onChange("email", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Phone"
                       type="text"
                       value={forms.sanitizeValue(data.phone)}
                       onChange={(event) => onChange("phone", event.target.value)}
            />
        </div>
        <div className="field">
            <BoolFilterField label="Uploaded image"
                             value={forms.sanitizeValue(data.imageUrl)}
                             onChange={(value) => onChange("imageUrl", value)}
            />
        </div>
        <div className="field">
            <CountFilterField label="Installations"
                              value={forms.sanitizeValue(data.installations)}
                              onChange={(value) => onChange("installations", value)}
            />
        </div>
        <div className="field">
            <TextField label="Role"
                       type="text"
                       value={forms.sanitizeValue(data.role)}
                       onChange={(event) => onChange("role", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Id"
                       type="text"
                       value={forms.sanitizeValue(data.id)}
                       onChange={(event) => onChange("id", event.target.value)}
            />
        </div>
    </div>
}

export default UserFilters;
