import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {AccountAvatar} from "@atttomyx/react-components";
import {forms} from "@atttomyx/shared-utils";
import {misc} from "@atttomyx/shared-constants";
import "./accountField.css";

const AccountField = (props) => {
    const { accounts, accountRenderer, value, onChange, required, multiple, label } = props;

    return <div className="account-field">
        <TextField label={label || (multiple ? "Accounts" : "Account")}
                   select={true} required={required}
                   value={multiple ? forms.sanitizeMulti(value) : forms.sanitizeValue(value)}
                   onChange={(event) => onChange(event.target.value)}
                   SelectProps={{
                       multiple: multiple,
                       renderValue: (value) => misc.NONE === value ? "None" : accountRenderer.render(value),
                   }}
        >
            {!multiple ? <MenuItem value="">&nbsp;</MenuItem> : null}
            {accounts.map((account) =>
                <MenuItem key={account.id} value={account.id}>
                    <span className="account-field-logo">
                        <AccountAvatar account={account} size="small" mode="no-title"/>
                    </span>
                    {account.branding && !account.branding.logoContainsName ? account.name : null}
                </MenuItem>)}
            <MenuItem value={misc.NONE}>None</MenuItem>
        </TextField>
    </div>
};

export default AccountField;
