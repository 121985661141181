import React from "react";
import {TextField} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";

const MoneyForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = modified.dollars >= 0 && modified.cents >= 0;

        onSync(modified);
    }

    return <div className="money-form">
        <div className="field">
            <TextField label="Dollars" required={true}
                       type="number"
                       value={forms.sanitizeValue(data.dollars)}
                       onChange={(event) => onChange("dollars", event.target.value)}
                       inputProps={{
                           min: 0,
                       }}
            />
        </div>
        <div className="field">
            <TextField label="Cents" required={true}
                       type="number"
                       value={forms.sanitizeValue(data.cents)}
                       onChange={(event) => onChange("cents", event.target.value)}
                       inputProps={{
                           min: 0,
                       }}
            />
        </div>
    </div>
}

export default MoneyForm;
