import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {AppBar, BottomNavigation, BottomNavigationAction} from "@mui/material";
import {
    AccountTree as AccountsIcon,
    Apps as AppsIcon,
    CreditCard as BillingIcon,
    MoreHoriz as MoreIcon,
    People as UsersIcon,
} from "@mui/icons-material";
import MoreMenu from "../moreMenu/moreMenu";
import {arrays} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {
    PAGE_ACCOUNT,
    PAGE_ACCOUNTS,
    PAGE_APP,
    PAGE_APPS,
    PAGE_BILLING,
    PAGE_BILLINGS,
    PAGE_USER,
    PAGE_USERS,
    PAGES_MAIN,
    PAGES_MORE
} from "../../constants";
import "./footer.css";

const PAGE_MORE = "more";
const PAGE_OTHER = "other";

const Footer = (props) => {
    const {history, user, onLogout} = props;
    const [showMore, setShowMore] = useState(false);

    const path = router.getCurrentPage(history);
    let page = PAGE_OTHER;

    if (arrays.contains(PAGES_MAIN, path)) {
        page = path;

    } else if (arrays.contains(PAGES_MORE, path)) {
        page = PAGE_MORE;

    } else if (path && path.startsWith(PAGE_APP)) {
        page = PAGE_APPS;

    } else if (path && path.startsWith(PAGE_ACCOUNT)) {
        page = PAGE_ACCOUNTS;

    } else if (path && path.startsWith(PAGE_USER)) {
        page = PAGE_USERS;

    } else if (path && path.startsWith(PAGE_BILLING)) {
        page = PAGE_BILLINGS;
    }

    return <div className="footer">
        <AppBar className="bottom-nav" position="fixed" color="default">
            <BottomNavigation
                value={page}
                showLabels={true}
            >
                {user.roles.apps ?
                    <BottomNavigationAction
                        value={PAGE_APPS}
                        label="Apps"
                        icon={<AppsIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_APPS)}
                    /> : null}
                {user.roles.billing ?
                    <BottomNavigationAction
                        value={PAGE_BILLINGS}
                        label="Billings"
                        icon={<BillingIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_BILLINGS)}
                    /> : null}
                {user.roles.accounts ?
                    <BottomNavigationAction
                        value={PAGE_ACCOUNTS}
                        label="Accounts"
                        icon={<AccountsIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_ACCOUNTS)}
                    /> : null}
                {user.roles.users ?
                    <BottomNavigationAction
                        value={PAGE_USERS}
                        label="Users"
                        icon={<UsersIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_USERS)}
                    /> : null}
                <BottomNavigationAction value={PAGE_MORE}
                                        label="More"
                                        icon={<MoreIcon/>}
                                        component="div"
                                        onClick={() => setShowMore(true)}
                />
            </BottomNavigation>
        </AppBar>
        {showMore ?
            <MoreMenu
                user={user}
                onCancel={() => setShowMore(false)}
                onLogout={onLogout}/> : null}
    </div>
}

export default withRouter(Footer);
