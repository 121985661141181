import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import AddUserForm from "../../forms/addUserForm/addUserForm";
import {forms} from "@atttomyx/shared-utils";

const freshData = () => {
    return {
        accountId: null,
        userId: null,
        valid: false,
    }
};

const AddOwnerDialog = (props) => {
    const {accounts, accountRenderer, users, onCancel, onAdd} = props;
    const [data, setData] = useState(freshData());

    return <Dialog
        open={true}
        aria-labelledby="add-owner-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add Owner
        </ClosableDialogTitle>
        <DialogContent className="add-owner-dialog">
            <AddUserForm
                accounts={accounts}
                accountRenderer={accountRenderer}
                users={users}
                data={data}
                onChange={setData}
            />
        </DialogContent>
        <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={() => onAdd(data.userId)}>
                Add
            </Button>
        </DialogActions>
    </Dialog>
}

export default AddOwnerDialog;
