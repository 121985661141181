import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {themes} from "@atttomyx/shared-constants";

const UserSettingsForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.theme);

        onSync(modified);
    }

    return <div className="user-settings-form">
        <div className="field">
            <TextField label="Theme" select={true} required={true}
                       value={forms.sanitizeValue(data.theme)}
                       onChange={(event) => onChange("theme", event.target.value)}
            >
                <MenuItem key={themes.LIGHT} value={themes.LIGHT}>Light</MenuItem>
                <MenuItem key={themes.DARK} value={themes.DARK}>Dark</MenuItem>
            </TextField>
        </div>
    </div>
}

export default UserSettingsForm;
