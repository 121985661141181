import React, {useEffect, useState} from "react";
import {UserField} from "@atttomyx/react-components";
import AccountField from "../../fields/accountField/accountField";
import {objects} from "@atttomyx/shared-utils";
import {isInAccount} from "../../utils/users";

const AddUserForm = (props) => {
    const { accountId, accounts, accountRenderer, users, data, onChange : onSync } = props;
    const [ availableAccounts, setAvailableAccounts ] = useState([]);
    const [ availableUsers, setAvailableUsers ] = useState([]);

    useEffect(() => {
        if (accountId) {
            const filtered = accounts.filter(account => account.id !== accountId);

            setAvailableAccounts(filtered);

        } else {
            setAvailableAccounts(accounts);
        }
    }, [accountId, accounts]);

    useEffect(() => {
        if (data.accountId) {
            const filtered = users.filter(user => isInAccount(user, data.accountId));

            setAvailableUsers(filtered);

        } else {
            setAvailableUsers([]);
        }
    }, [availableAccounts, data.accountId]);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = modified.accountId && modified.userId;

        onSync(modified);
    }

    return <div className="add-user-form">
        <div className="field top-margin">
            <AccountField
                required={true}
                accounts={availableAccounts}
                accountRenderer={accountRenderer}
                value={data.accountId}
                onChange={(accountId) => onChange("accountId", accountId)}
            />
        </div>
        <div className="field">
            <UserField
                required={true}
                users={availableUsers}
                value={data.userId}
                onChange={(userId) => onChange("userId", userId)}
            />
        </div>
    </div>
}

export default AddUserForm;
