import React, {useState} from "react";
import {Button, IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {AddCircle as AddIcon, Delete as RemoveIcon} from "@mui/icons-material";
import AddRedactedFieldDialog from "../../dialogs/addRedactedFieldDialog/addRedactedFieldDialog";
import {arrays, objects} from "@atttomyx/shared-utils";
import "./appRedactedForm.css";

const AppRedactedForm = (props) => {
    const { data, onChange } = props;
    const [ showAddField, setShowAddField ] = useState(false);

    const removeField = (type, field) => {
        const modified = objects.deepCopy(data);
        const array = modified[type];

        if (arrays.contains(array, field)) {
            arrays.removeFrom(array, field);

            modified[type] = array;
            onChange(modified);
        }
    };

    const addField = (type, field) => {
        const modified = objects.deepCopy(data);
        const array = modified[type];

        if (!arrays.contains(array, field)) {
            arrays.addTo(array, field);

            modified[type] = array;
            onChange(modified);
        }
    };

    const renderField = (type, field) => {
        return <TableRow key={field}>
            <TableCell width="90%">
                {field}
            </TableCell>
            <TableCell width="10%">
                <IconButton color="secondary" title={"Remove " + field}
                            onClick={() => removeField(type, field)}>
                    <RemoveIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    };

    return <div className="app-redacted-form">
        <Typography variant="h5">
            Redacted Fields
        </Typography>
        <Table size="small" cellPadding={0} cellSpacing={0} className="striped fields">
            <TableBody>
                {data.fields.map((standard) => renderField("fields", standard))}
                {data.settings.map((other) => renderField("settings", other))}
            </TableBody>
        </Table>
        <Button color="primary" variant="text" className="add-field"
                onClick={() => setShowAddField(true)}>
            <AddIcon/> Add Field
        </Button>
        {showAddField ?
            <AddRedactedFieldDialog
                onCancel={() => setShowAddField(false)}
                onAddField={(field) => {
                    addField("fields", field);
                    setShowAddField(false);
                }}
                onAddSetting={(field) => {
                    addField("settings", field);
                    setShowAddField(false);
                }}
            /> : null}
    </div>
};

export default AppRedactedForm;
