import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {forms} from "@atttomyx/shared-utils";
import {ADD_NEW} from "../../constants";

const BillingField = (props) => {
    const { billingRenderer, value, required, onChange : onSync, onAddNew } = props;

    const onChange = (billingId) => {
        if (ADD_NEW === billingId) {
            onAddNew();

        } else {
            onSync(billingId);
        }
    }

    return <div className="billing-field">
        <TextField label="Billing"
                   select={true}
                   required={required}
                   value={forms.sanitizeValue(value)}
                   onChange={(event) => onChange(event.target.value)}
        >
            <MenuItem value="">&nbsp;</MenuItem>
            {Object.entries(billingRenderer.idToRendered).map(([key, value]) =>
                <MenuItem key={key} value={key}>{value}</MenuItem>)}
            {onAddNew ? <MenuItem key={ADD_NEW} value={ADD_NEW}>{ADD_NEW}</MenuItem> : null}
        </TextField>
    </div>
};

export default BillingField;
