import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Divider, Typography} from "@mui/material";
import {AccountAvatar, UserForm} from "@atttomyx/react-components";
import UserRoleForm from "../../forms/userRoleForm/userRoleForm";
import * as userService from "../../services/internal/users";
import {arrays, forms} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./addUserPage.css";

const freshData = () => {
    return {
        imageUrl: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        birthday: null,
        valid: false,
    }
};

const freshRoles = () => {
    return [];
};

const AddUserPage = (props) => {
    const { snackbar, match, accounts, onSave } = props;
    const [ account, setAccount ] = useState({})
    const [ data, setData ] = useState(freshData());
    const [ roles, setRoles ] = useState(freshRoles());
    const [ saving, setSaving ] = useState(false);

    useEffect(() => {
        const accountId = match.params.accountId;

        setAccount(arrays.findEntity(accounts, accountId));
    }, []);

    const resetForm = () => {
        setData(freshData());
        setRoles(freshRoles());
    }

    const submitForm = () => {
        setSaving(true);

        const added = {
            imageUrl: data.imageUrl,
            firstName: data.firstName,
            lastName: data.lastName,
            alias: data.alias,
            email: data.email,
            phone: data.phone,
            birthday: data.birthday,
            roles: roles,
        };

        const success = (user) => {
            resetForm();
            setSaving(false);

            snackbar.setSuccess("User saved");
            onSave(account.id, user);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        userService.createUser(account.id, added, success, failure);
    }

    const valid = data.valid && account;
    const modified = forms.differ(freshData(), data) || forms.differ(freshRoles(), roles);

    return saving ?
        <div className="add-user-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="add-user-page">
            {account ?
                <>
                    <AccountAvatar account={account} mode="no-title"
                                   size={account.branding && account.branding.logoContainsName ? "plus" : "medium"}/>
                    {account.branding && !account.branding.logoContainsName ?
                        <Typography variant="caption">
                            {account.name}
                        </Typography> : null}
                    <Divider/>
                </> : null}
            <div className="cards">
                <div className="card">
                    <UserForm
                        snackbar={snackbar}
                        data={data}
                        onChange={setData}
                        showImage={false}
                        showPhone={true}
                        showBirthday={true}
                    />
                </div>
                <div className="card">
                    <UserRoleForm
                        roles={roles}
                        onChange={setRoles}
                    />
                </div>
            </div>
            <div className="actions">
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default AddUserPage;
